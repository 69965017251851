import React, {useEffect, useState} from 'react';
import { Select, Option, Spinner } from '@contentful/forma-36-react-components';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import {AppInstallationParameters} from "./ConfigScreen";
import {createClient} from "contentful-management";

interface FieldProps {
  sdk: FieldExtensionSDK;
}

interface Trip{
  id: string,
  title: string,
}

const Field = (props: FieldProps) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState<Trip[]>([]);
    const cma = createClient(
        { apiAdapter: props.sdk.cmaAdapter },
    )

    useEffect(() => {

        const fetchData = async () => {
            props.sdk.window.startAutoResizer();
            const relativeUrl = (props.sdk.parameters.instance as any).relativeUrl
            const djangoHost = (props.sdk.parameters.installation as AppInstallationParameters).djangoHost
            if(props.sdk.entry.fields.trip){
                const linkedTrip = props.sdk.entry.fields.trip
                linkedTrip.onValueChanged( async (value) => {
                    if(value?.sys?.id){
                        const trip = await getTrip(value.sys.id)
                        const djangoId = trip.fields.linkedVirtualOfficeTrip['en-US']
                        const filters = {
                            'trip__id': djangoId
                        }
                        await updateItems(`${djangoHost}${relativeUrl}`, filters)
                    }
                })
            }
            else{
                await updateItems(`${djangoHost}${relativeUrl}`)
            }

        }

        const updateItems = async (url: string, filters?: {[key: string]:string}) => {
            let filterQuery = ''
            if(filters){
                const filterItems = Object.entries(filters).map(([key, value]) => `${key}=${value}`).join('&')
                filterQuery = `?${filterItems}`
            }

            const response = await (await fetch(`${url}${filterQuery}`)).json()
            const choices = [...[{id: '', title:'Choose a value'}],...response]
            setItems(choices)
            setIsLoaded(true)
            if(!props.sdk.field.getValue()){
                update(choices[0].id)
            }
        }
        fetchData()
            .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTrip = async (id: string) => {
        const sdk = props.sdk
        const space = await cma.getSpace(sdk.ids.space)
        const environment = await space.getEnvironment(sdk.ids.environment)
        return await environment.getEntry(id)
    }

    const update = (value: string) => {
        props.sdk.field.setValue(value)
    }

    if(isLoaded) {
        return (
            <Select id="optionSelect" value={props.sdk.field.getValue()} name="optionSelect" onChange={(event) => update(event.target.value)}>
              {
                items.map((option) => {
                  return <Option key={option.id} value={option.id}>{option.title}</Option>
                })
              }
            </Select>);
    }
    else{
        return <Spinner color="primary" />
    }
};

export default Field;
